<template>
  <el-card>
    <div class="top">
      <el-button type="primary" @click="handleCreateGoodsType({typeLevel: 1, pid: 0, pName: '无'}, 'add')">新建一级商品分类</el-button>
    </div>
    <div class="tableWrap">
      <el-table
        :data="tableData"
        style="width: 100%;margin-bottom: 20px;"
        row-key="id"
        default-expand-all
        height="600"
        :tree-props="{children: 'child', hasChildren: 'hasChildren'}">
        <el-table-column
          align="center"
          prop="date"
          label="序号"
          width="60">
        </el-table-column>
        <el-table-column
          prop="name"
          label="名称"
          width="180">
        </el-table-column>
        <el-table-column
          align="center"
          label="图标"
          width="180">
          <template slot-scope="scope">
            <img style="width: 100px;" :src="scope.row.url" alt="">
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="childCount"
          label="子分类数"
          width="100">
        </el-table-column>
        <el-table-column
          align="center"
          prop="goodsCount"
          label="商品总数"
          width="100">
        </el-table-column>
        <el-table-column
          align="center"
          prop="onlineGoodsCount"
          label="上架商品总数"
          width="100">
        </el-table-column>
        <el-table-column
          align="center"
          label="排序"
          width="150">
          <template slot-scope="scope">
            <el-button @click="handleSort(scope.row)" style="font-size: 36px;" icon="el-icon-caret-top" type="text"></el-button>
            <el-button @click="handleSort(scope.row)" style="font-size: 36px;"  icon="el-icon-caret-bottom" type="text"></el-button>
          </template>
          
        </el-table-column>
        <el-table-column
          align="center"
          prop="createTime"
          label="创建时间"
          width="180">
        </el-table-column>
        <el-table-column
          align="center"
          prop="updateTime"
          label="更新时间"
          width="180">
        </el-table-column>
        <el-table-column
          align="center"
          fixed="right"
          label="操作"
          width="220">
          <template slot-scope="scope">
            <el-button size="mini" v-if="scope.row.typeLevel < 3" type="text" @click="handleCreateGoodsType({typeLevel: scope.row.typeLevel + 1, pid: scope.row.id, pName: scope.row.name}, 'add')">添加子类型</el-button>
            <el-button size="mini" type="text" @click="handleCreateGoodsType(scope.row, 'edit')" style="margin-right: 15px;">编辑</el-button>
            <el-popconfirm
              title="您确定要删除该条数据吗?"
              @confirm="handleDeleteGoodsType(scope.row)"
            >
              <el-button slot="reference" size="mini" type="text" style="color: red;" >删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog :title="typeDialogTitle" width="600px" :visible.sync="typeVisible">
      <el-form :model="typeForm">
        <el-form-item style="width: 400px;" label="上级分类：" label-width="120px">
          <span>{{ typeForm.pName }}</span>
        </el-form-item>
        <el-form-item style="width: 400px;"  label="分类名称：" label-width="120px">
          <el-input v-model="typeForm.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item style="width: 400px;"  label="分类图片：" label-width="120px">
          <div v-if="!typeForm.url" class="uploadWrap" @click="handleSelectMaterial">
            <i class="el-icon-plus"></i>
          </div>
          <img v-else class="uploadWrap" :src="typeForm.url" alt="" @click="handleSelectMaterial">
        </el-form-item>
        <el-form-item v-if="typeForm.typeLevel == 3" label="商品规格名称：">
          <el-tag
            :key="tag"
            v-for="tag in goodsTagList"
            closable
            :disable-transitions="false"
            @close="handleClose(tag)">
            {{tag}}
          </el-tag>
          <template v-if="goodsTagList.length < 2">
            <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="inputValue"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            >
            </el-input>
            <el-button v-else class="button-new-tag" size="small" @click="showInput">添加标签</el-button>
          </template>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="typeVisible = false, handleResetTypeForm()">取 消</el-button>
        <el-button type="primary" @click="handleAddGoodsType">确 定</el-button>
      </div>
    </el-dialog>
    <MaterialDialog :visible.sync="materialVisible" @handleTypeImg="handleTypeImg" />
  </el-card>
</template>
<script>
import MaterialDialog from '../../../components/materialDialog/index'
export default {
  name: 'ShopType',
  components: {
    MaterialDialog
  },
  data () {
    return {
      tableData: [],
      /* 新建分类表单 */
      typeVisible: false,
      typeForm: {
        id: 0,
        pid: 0,
        typeLevel: 1,
        pName: '',
        name: '',
        url: ''
      },
      /* 标签 */
      goodsTagList: [], // 标签列表
      inputVisible: false,
      inputValue: '',
      materialVisible: false,
      typeDialogTitle: ''
    }
  },
  created () {
    this.handleGetGoodsTypeList()
  },
  methods: {
    /* 标签 */
    handleClose(tag) {
      this.goodsTagList.splice(this.goodsTagList.indexOf(tag), 1);
    },
    // 标签输入框
    showInput() { 
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    // 添加标签
    handleInputConfirm() {
      let inputValue = this.inputValue
      if (inputValue) {
        this.goodsTagList.push(inputValue)
      }
      this.inputVisible = false
      this.inputValue = ''
    },
    // 新建分类
    handleCreateGoodsType (row, action) {
      this.typeForm = Object.assign(this.typeForm, row)
      console.log('typeForm', this.typeForm);
      this.goodsTagList = row.tags ? row.tags : []
      this.typeDialogTitle = action == 'add' ? '新建商品分类：' : '编辑商品分类：'
      this.typeVisible = true
    },
    // 重置表单
    handleResetTypeForm () {
      this.typeForm = {
          id: 0,
          pid: 0,
          typeLevel: 1,
          pName: '',
          name: '',
          url: ''
        }
      this.goodsTagList = []
    },
    /* 排序 */
    handleSort (row) {
      console.log('row', row);
    },
    async handleDeleteGoodsType (row) {
      const { code, msg } = await this.$api.shopManageApi.deleteGoodsType({id: row.id})
      if (code == 200) {
        this.$message.success('操作成功')
        await this.handleGetGoodsTypeList()
      } else {
        this.$message.error(msg)
      }
    },
    handleSelectMaterial () {
      this.materialVisible = true
    },
    handleTypeImg (url) {
      this.typeForm.url = url
    },
    // 商品分类列表
    async handleGetGoodsTypeList () {
      const { code, msg, data	 } = await this.$api.shopManageApi.getGoodsTypeList()
      if (code == 200) {
        this.tableData = data
        console.log('data	', data);
      } else {
        this.$message.error(msg)
      }
    },
    // 新增商品分类
    async handleAddGoodsType () {
      const { pid, typeLevel, url, name, id } = this.typeForm
      const postData = {
        id: id ? id : '',
        pid: id ? undefined : pid,
        typeLevel,
        url,
        name,
        tags: typeLevel == 3 ? this.goodsTagList : undefined
      }
      const { code, msg } = id ? await this.$api.shopManageApi.updateGoodsType(postData) : await this.$api.shopManageApi.addGoodsType(postData)
      if (code == 200) {
        this.$message.success('操作成功')
        this.typeVisible = false
        this.handleResetTypeForm()
        await this.handleGetGoodsTypeList()
      } else {
        this.$message.error(msg)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.tableWrap{
  margin-top: 20px;
  overflow: scroll;
}
.el-tag + .el-tag {
    margin-left: 10px;
  }
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.uploadWrap{
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  font-size: 26px;
  background-color: #F0F0F0;
  cursor: pointer;
}
</style>
